<template>
  <b-card>
    <FormBuilder
      label="CMS"
      update
      :data="$store.state.cms.detailData"
      :fields="fields"
      :loading-detail="$store.state.cms.loadingDetail"
      :loading-submit="$store.state.cms.loadingSubmit"
      :diff-only="false"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'name',
          label: 'Name',
          required: true,
        },
        {
          key: 'slug',
          label: 'Slug',
          required: true,
        },
        {
          key: 'type',
          label: 'Type',
          type: 'select',
          options: [
            {
              text: 'Page',
              value: 'page',
            },
            {
              text: 'Article',
              value: 'article',
            },
            {
              text: 'Press',
              value: 'press',
            },
          ],
          required: true,
        },
        {
          key: 'featuredImage',
          label: 'Featured Image',
          type: 'image',
          required: true,
        },
        {
          key: 'summary',
          label: 'Summary',
          required: true,
        },
        {
          key: 'content',
          label: 'Content',
          type: 'tinymce',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('cms/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('cms/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('cms/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

<style scoped>
</style>
